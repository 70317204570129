// Custom Properties in Hammersmith
:root {
    // Colors
    --theme-primary: #5eb132;
    --theme-secondary: #e6f0d9;
    --theme-tertiary: #c4d4d4;
    --theme-on-default: #282727;

    --theme-primary-transparent: #5eb13200;
    --theme-primary-opaque: rgb(94, 177, 50, 0.05);

    --theme-attention: var(--theme-primary);
    --theme-promotion: var(--theme-primary);
    --theme-cta: var(--theme-primary);
    --theme-cta-font-color: #fff;

    // Fonts
    --ff-base: 'Archivo', sans-serif;
    --ff-menu: 'Archivo', sans-serif;
    --ff-headline: 'Archivo', sans-serif;
    --font-base: 'Archivo', sans-serif;
    --font-headlines: 'Archivo', sans-serif;
    --font-product-price: 'Archivo', sans-serif;

    --theme-headlines: 'BricolageGrotesque-ExtraBold', sans-serif;
    --theme-subheadlines: 'BricolageGrotesque', sans-serif;

    --menu-color-primary: var(--theme-on-default);
    --color-headline: var(--theme-on-default);
    --color-subheadline: var(--theme-on-default);
    --color-black: var(--theme-on-default);
    --color-gray-800: var(--theme-on-default);

    --fs-main-navigation-mobile: 20px;

    // MainNavigationVB
    --color-background: #e6f0d9;
    --color-icons: #003a38;

    // Mobile Menu
    --mobile-menu-top: 110px;

    // Main Navigation Custom
    --main-navigation-custom-dropdown-background-color: var(--theme-secondary);

    // Burger Menu
    --burger-menu-icon-color: #003a38;

    // Cart
    --bg-shipping-cost-bar: #f1f7ec;
}
