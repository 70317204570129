@font-face {
    font-family: 'Archivo';
    src: url('../assets/fonts/Archivo/Archivo-Regular.ttf') format('truetype');
    font-display: swap;
    font-style: normal;
    font-weight: 400 600;
}

@font-face {
    font-family: 'Archivo';
    src: url('../assets/fonts/Archivo/Archivo-Bold.ttf') format('truetype');
    font-display: swap;
    font-style: normal;
    font-weight: 700 800;
}

@font-face {
    font-family: 'Archivo';
    src: url('../assets/fonts/Archivo/Archivo-BoldItalic.ttf') format('truetype');
    font-display: swap;
    font-style: italic;
    font-weight: 700 800;
}

@font-face {
    font-family: 'Archivo';
    src: url('../assets/fonts/Archivo/Archivo_SemiCondensed-ExtraBold.ttf') format('truetype');
    font-display: swap;
    font-style: normal;
    font-weight: 900 1000;
}

@font-face {
    font-family: 'Archivo';
    src: url('../assets/fonts/Archivo/Archivo-VariableFont_wdth\,wght.ttf') format('truetype');
    font-display: swap;
    font-style: normal;
    font-weight: 200 1000;
}

@font-face {
    font-family: 'Archivo';
    src: url('../assets/fonts/Archivo/Archivo-Italic-VariableFont_wdth\,wght.ttf') format('truetype');
    font-display: swap;
    font-style: italic;
    font-weight: 200 1000;
}

@font-face {
    font-family: 'Archivo';
    src: url('../assets/fonts/Archivo/Archivo-Italic.ttf') format('truetype');
    font-display: swap;
    font-style: italic;
    font-weight: 100 100;
}

@font-face {
    font-family: 'BricolageGrotesque';
    src: url('../assets/fonts/BricolageGrotesque/BricolageGrotesque_48pt-Medium.ttf') format('truetype');
    font-display: swap;
    font-style: normal;
    font-weight: 100 100;
}

@font-face {
    font-family: 'BricolageGrotesque-ExtraBold';
    src: url('../assets/fonts/BricolageGrotesque/BricolageGrotesque_48pt-ExtraBold.ttf') format('truetype');
    font-display: swap;
    font-style: normal;
    font-weight: 600 900;
}
